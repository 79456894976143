import React, { useState } from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/hwt.jpg";
import { Modal, Button } from "react-bootstrap";
import HubspotContactForm from "./hubspot/HubspotContactForm";

const CoursesPage = () => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="course-details__content">
                <div className="course-details__top">
                  <div className="course-details__top-left">
                    <h2 className="course-details__title">
                      Handwriting without tears
                    </h2>
                  </div>
                  <div className="course-details__top-right">
                    <a className="course-one__category">For schools</a>
                  </div>
                </div>
                <div className="course-one__image">
                  <img src={image} alt="" width={100} />
                </div>
                <div className="tab-content course-details__tab-content ">
                  <div
                    className="tab-pane show active  animated fadeInUp"
                    role="tabpanel"
                    id="overview"
                  >
                    <p className="course-details__tab-text">
                      {" "}
                      Patiently waiting for your child to stop scribbling and
                      start writing? Our Handwriting Without Tears (HWT)
                      programme can make that happen remarkably sooner! HWT is a
                      revolutionary method to facilitate handwriting skills
                      backed by three decades of research and experience,
                      developed by Ms. Jan Olsen, an Occupational Therapist from
                      the USA.{" "}
                    </p>
                    <br />
                    <h3>How it works?</h3>
                    <ul>
                      <li>
                        We use child-friendly, multisensory tools to help your
                        little one develop the perfect writing grip and adopt a
                        technically-sound writing style. Practice workbooks and
                        teacher’s guides are available from Pre-KG to Grade 5,
                        for both print and cursive handwritings.
                      </li>
                      <li>
                        We devote personal attention to help your child overcome
                        persistent handwriting issues like illegibility,
                        alphabet mirroring, incorrect starting points and
                        dysgraphia, among others.
                      </li>
                      <li>
                        With the latest HWT techniques, good handwriting will
                        become a natural skill thereby, allowing your child to
                        focus on other important aspects of learning. Just 15
                        minutes of practice in a day will reveal astounding
                        results! Don’t go just by our word, 30 million users
                        worldwide vouch for it too!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="course-details__price">
                <p className="course-details__price-text">
                  Talk to our executive and enroll now!{" "}
                </p>
                <Button
                  onClick={handleShow}
                  className="thm-btn course-details__price-btn"
                  variant="default"
                >
                  Enquire Now
                </Button>
              </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
